'use strict';

(function (angular) {

    dataService.$inject = ['$log', 'productService', 'PRODUCT_DATA', 'BAZAARVOICE_ACTIVE', 'DISPLAY_VIDEO', 'SIZE_SORTING_ORDER'];
    angular
        .module('jwsdw.services')
        .factory('dataService', dataService);

    /**
     * @class jwsdwProductDetail.dataService
     * @description Data service that allows access to the data layer of the product details
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} productService service to provide product information (used for creation of variants item)
     * @param {Object} PRODUCT_DATA object holding all product data provided trough jwsdwProductDetailSettings
     * @param {Boolean} BAZAARVOICE_ACTIVE true if bazaarvoice is active
     * @param {Boolean} DISPLAY_VIDEO boolean if video should be shown on pdp (provided through jwsdwProductDetailSettings)
     * @param {Object} SIZE_SORTING_ORDER size ids in sorting order
     * @returns {Object} service object that returns public methods
     */
    function dataService($log, productService, PRODUCT_DATA, BAZAARVOICE_ACTIVE, DISPLAY_VIDEO, SIZE_SORTING_ORDER) { /* eslint max-statements: ["error", 60] */
        var service,
            _imageData,
            _mediaData,
            _360Data,
            _sizes,
            _sizeGuideSizeMap,
            _sizeType = PRODUCT_DATA.sizeType,
            _colors,
            _size,
            _color,
            _variant,
            _isSale,
            _productRating,
            _sale,
            _standard,
            _bestPrice,
            _priceReduction,
            _productId,
            _priceWithoutVAT,
            _ats,
            _availabilityId,
            _freeShipping,
            _activeFlags,
            _coBrandingLogo,
            _productPromotions = null;

        // We need to add this checks because the data.service file will be available everywhere the services module is injected
        // This is just a temporary fix until angular is removed from the project
        if (PRODUCT_DATA && Object.keys(PRODUCT_DATA).length > 0) {
            PRODUCT_DATA.size = PRODUCT_DATA.size && PRODUCT_DATA.size.replace('B', '');
            PRODUCT_DATA.variants = PRODUCT_DATA.variants.map(function (variant) {
                variant.attributes.size.id = variant.attributes.size.id.replace('B', '');
                return variant;
            });
        }

        $log.debug('jwsdw.productDetail.dataService: Using following data to built the page: ', PRODUCT_DATA);

        // define public methods
        service = {
            'productName': productName,
            'productType': productType,
            'productRating': productRating,
            'price': price,
            'priceOld': priceOld,
            'bestPrice': bestPrice,
            'isSale': isSale,
            'priceReduction': priceReduction,
            'productId': productId,
            'colorCode': colorCode,
            'imageData': imageData,
            'mediaData': mediaData,
            'threeSixtyData': threeSixtyData,
            'variants': variants,
            'sizes': sizes,
            'sizeMap': sizeMap,
            'hasSizes': hasSizes,
            'sizeType': sizeType,
            'colors': colors,
            'sizeById': sizeById,
            'colorById': colorById,
            'initialSize': initialSize,
            'initialColor': initialColor,
            'freeShipping': freeShipping,
            'availabilityId': availabilityId,
            'availabilityMessage': availabilityMessage,
            'getProductFlags': getProductFlags,
            'getCoBrandingProductFlag': getCoBrandingProductFlag,
            'priceWithoutVAT': priceWithoutVAT,
            'ats': ats,
            'imageUrl': window.jwsdwUtil.urlUtils.imageUrl,
            'videoUrl': window.jwsdwUtil.urlUtils.videoUrl,
            'productImageUrl': window.jwsdwUtil.urlUtils.productImageUrl,
            'productImageUrlObject': window.jwsdwProductDetailSettings ? window.jwsdwProductDetailSettings.productImages : {},
            'update': update,
            'allMedia': allMedia,
            'mediaIndexById': mediaIndexById,
            'mediaById': mediaById,
            'transforms': {
                'full': { 'scaleWidth': 1800, 'scaleHeight': 2600, 'format': 'jpg' },
                'sfull': { 'scaleWidth': 1400, 'scaleHeight': 2200, 'format': 'jpg' },
                'xlarge': { 'scaleWidth': 612, 'scaleHeight': 882, 'format': 'jpg' },
                'large': { 'scaleWidth': 372, 'scaleHeight': 536, 'format': 'jpg' },
                'medium': { 'scaleWidth': 425, 'scaleHeight': 612, 'format': 'jpg' },
                'small': { 'scaleWidth': 600, 'scaleHeight': 864, 'format': 'jpg' },
                'thumbGallery': { 'scaleWidth': 80, 'format': 'jpg' },
                'productDescription': { 'scaleWidth': 600, 'format': 'jpg' },
                'techLab-large': { 'scaleWidth': 700, 'scaleHeight': 830, 'format': 'jpg' },
                'techLab-medium': { 'scaleWidth': 1280, 'scaleHeight': 1520, 'format': 'jpg' },
                'techLab-small': { 'scaleWidth': 960, 'scaleHeight': 1140, 'format': 'jpg' },
                'primaloft-large': { 'scaleWidth': 700, 'scaleHeight': 830, 'format': 'jpg' },
                'primaloft-medium': { 'scaleWidth': 1280, 'scaleHeight': 1520, 'format': 'jpg' },
                'primaloft-small': { 'scaleWidth': 960, 'scaleHeight': 1140, 'format': 'jpg' },
                'polartec-large': { 'scaleWidth': 700, 'scaleHeight': 830, 'format': 'jpg' },
                'polartec-medium': { 'scaleWidth': 1280, 'scaleHeight': 1520, 'format': 'jpg' },
                'polartec-small': { 'scaleWidth': 960, 'scaleHeight': 1140, 'format': 'jpg' },
                'swatch': { 'scaleWidth': 50, 'format': 'jpg' }
            },
            'productPromotions': productPromotions
        };

        window.dataService = service;

        if (BAZAARVOICE_ACTIVE) {
            window.jwsdwMediator.subscribe('ratingLoaded', function (productFeebdack) {
                _productRating = {
                    'averageRating': productFeebdack.rating,
                    'reviewCount': productFeebdack.count
                };
            });
        }

        return service;

        /**
         * @description Method to update of all values
         * @param {String} size Size Id
         * @param {String} color Color Id
         * @param {Boolean} preventImageReload Boolean value for determing if images need to reload
         * @returns {void}
         * @memberOf jwsdwProductDetail.dataService
         */
        function update(size, color, preventImageReload) {
            size = size || _initialSize();
            color = color || _initialColor();

            if (size !== _size || color !== _color) {
                _size = size;
                _color = color;

                // variant
                _variant = _getVariant(size, color);

                // if color of spezific size is not available, get first available size
                if (!_variant) {
                    _variant = _getVariant(null, color);
                    if (_variant) {
                        _size = _variant.attributes.size.id;
                        window.jwsdwMediator.publish('changeSize', _size);
                    }
                }

                // sizes
                _initSizes();

                // colors
                _initColors();

                // isSale
                _isSale = _variant ? _variant.pricing.sale !== _variant.pricing.standard : false;

                // prices and priceReduction
                _sale = _variant ? _variant.pricing.sale : PRODUCT_DATA.pricing.sale;
                _standard = _variant ? _variant.pricing.standard : PRODUCT_DATA.pricing.standard;
                _priceReduction = Math.round(100 - _sale / _standard * 100);
                _bestPrice = _variant ? _variant.pricing.bestPrice : PRODUCT_DATA.pricing.bestPrice;

                // productId
                _productId = _variant ? _variant.id : PRODUCT_DATA.ID;

                // priceWithoutVAT
                _priceWithoutVAT = _variant ? _variant.pricing.priceWithoutVAT : PRODUCT_DATA.pricing.priceWithoutVAT;

                // ats
                _ats = _variant ? _variant.availability.ats : PRODUCT_DATA.availability.ats;

                // availabilityId
                _availabilityId = _variant ? _variant.availability.status : PRODUCT_DATA.availability.status;

                // activeFlag
                _activeFlags = _variant ? _variant.activeFlags : PRODUCT_DATA.activeFlags;

                // cobranding flag
                _coBrandingLogo = _variant ? _variant.coBrandingLogo : PRODUCT_DATA.coBrandingLogo;

                // productsPromotions
                _productPromotions = PRODUCT_DATA.productsPromotions[_variant.id];

                // free shipping info
                _freeShipping =
                    (PRODUCT_DATA.freeShipping.freeShippingFrom && _variant && (_sale || _standard) > PRODUCT_DATA.freeShipping.freeShippingFrom) ||
                    PRODUCT_DATA.freeShipping.isEA;

                // image data
                if (!preventImageReload) {
                    _parseImageData();
                    _parseMediaData();
                    _parse360Data();
                    _includeVideo();
                }
            }
        }

        /**
         * @description Method to return the product name
         * @returns {String} productName
         * @memberOf jwsdwProductDetail.dataService
         */
        function productName() {
            return PRODUCT_DATA.displayedName || PRODUCT_DATA.name;
        }

        /**
         * @description Method to return the product type (this is the SEO name of the product)
         * @returns {String} productType
         * @memberOf jwsdwProductDetail.dataService
         */
        function productType() {
            return PRODUCT_DATA.type;
        }

        /**
         * @description Method to check if its a reduced product
         * @returns {String} isSale
         * @memberOf jwsdwProductDetail.dataService
         */
        function isSale() {
            return _isSale;
        }

        /**
         * @description Method to return the product rating
         * @returns {Object} productRatings
         * @memberOf jwsdwProductDetail.dataService
         */
        function productRating() {
            return _productRating;
        }

        /**
         * @description Method to return the products sale price or standard price if no sale is available
         * @returns {String} price
         * @memberOf jwsdwProductDetail.dataService
         */
        function price() {
            return _sale;
        }

        /**
         * @description Method to return the products promotions
         * @returns {Object} promotions
         * @memberOf jwsdwProductDetail.dataService
         */
        function productPromotions() {
            return _productPromotions.length ? _productPromotions[0] : null;
        }

        /**
         * @description Method to return the products standard price if sale is available
         * @returns {String} priceOld
         * @memberOf jwsdwProductDetail.dataService
         */
        function priceOld() {
            return _standard;
        }

        /**
         * @description Method to return the products standard price if sale is available
         * @returns {String} priceOld
         * @memberOf jwsdwProductDetail.dataService
         */
        function bestPrice() {
            return _bestPrice;
        }

        /**
         * @description Method to return the products price reduction in percent
         * @returns {Number} priceReduction
         * @memberOf jwsdwProductDetail.dataService
         */
        function priceReduction() {
            return _priceReduction;
        }

        /**
         * @description Method to return full productid/style
         * @returns {String} Full productid/style for given size and color
         * @memberOf jwsdwProductDetail.dataService
         */
        function productId() {
            return _productId;
        }

        /**
         * @description Method to return full productid/style
         * @returns {String} Full productid/style for given size and color
         * @memberOf jwsdwProductDetail.dataService
         */
        function colorCode() {
            return _color;
        }

        /**
         * @description Method to return product price without VAT
         * @returns {String} priceWithoutVAT
         * @memberOf jwsdwProductDetail.dataService
         */
        function priceWithoutVAT() {
            return _priceWithoutVAT;
        }

        /**
         * @description Method to return number of available products for this size/color variant
         * @returns {String} ats
         * @memberOf jwsdwProductDetail.dataService
         */
        function ats() {
            return _ats;
        }


        /**
         * @description Method to return availability ID
         * @returns {String} availabilityId
         * @memberOf jwsdwProductDetail.dataService
         */
        function availabilityId() {
            return _availabilityId;
        }

        /**
         * @description Method to return the product's ID of the flag or null
         * @returns {String} activeFlag
         * @memberOf jwsdwProductDetail.dataService
         */
        function getProductFlags() {
            return _activeFlags;
        }

        /**
         * @description Method to return the cobranding product flag
         * @returns {String} coBrandingLogo
         * @memberOf jwsdwProductDetail.dataService
         */
        function getCoBrandingProductFlag() {
            return _coBrandingLogo;
        }

        /**
         * @description Method to return an availability message
         * @returns {String} availability message
         * @memberOf jwsdwProductDetail.dataService
         */
        function availabilityMessage() {
            switch (_availabilityId) {
                case 'IN_STOCK':
                    return PRODUCT_DATA.availability.inStockMsg;
                case 'NOT_AVAILABLE':
                    return null;
                default:
                    return null;
            }
        }

        /**
         * @description Method to return all variants
         * @returns {Object[]} variants
         * @memberOf jwsdwProductDetail.dataService
         */
        function variants() {
            return PRODUCT_DATA.variants;
        }

        /**
         * @description Method to return all available sizes for this product
         * @returns {Object[]} sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function sizes() {
            return _sizes;
        }

        /**
         * @description Method to return all available sizes for this product
         * @returns {Object[]} sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function sizeMap() {
            return _sizeGuideSizeMap.sizes;
        }

        /**
         * @description Method to check if product has sizes
         * @returns {Boolean} true if product has sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function hasSizes() {
            return Object.keys(_sizeGuideSizeMap.sizes).some(function (type) {
                return _sizeGuideSizeMap.sizes[type].length > 0;
            });
        }

        /**
         * @description Method to return size type of product
         * @returns {String} sizeType
         * @memberOf jwsdwProductDetail.dataService
         */
        function sizeType() {
            return _sizeType;
        }

        /**
         * @description Method to return all available colors for this product
         * @returns {Object[]} colors
         * @memberOf jwsdwProductDetail.dataService
         */
        function colors() {
            return _colors;
        }

        /**
         * @description Method to get size by ID
         * @param {String} id id
         * @returns {Object} size
         * @memberOf jwsdwProductDetail.dataService
         */
        function sizeById(id) {
            if (_sizes) {
                return _sizes.find(function (size) {
                    return id === size.id;
                });
            }
        }

        /**
         * @description Method to get color by ID
         * @param {String} id id
         * @returns {Object} color
         * @memberOf jwsdwProductDetail.dataService
         */
        function colorById(id) {
            if (colors()) {
                return colors().find(function (color) {
                    return id === color.id;
                });
            }
        }

        /**
         * @description Method to set initally selected size
         * @returns {Object} size
         * @memberOf jwsdwProductDetail.dataService
         */
        function initialSize() {
            return sizeById(_initialSize());
        }

        /**
         * @description Method to set initally selected color
         * @returns {Object} color
         * @memberOf jwsdwProductDetail.dataService
         */
        function initialColor() {
            let color = colorById(_initialColor());
            return color;
        }

        /**
         * @description Method to determine whether product has free shipping
         * @returns {Boolean} true if shipping is free
         */
        function freeShipping() {
            return _freeShipping;
        }

        /**
         * @description Method to parse and returns the image data
         * @returns {Object} Parsed data
         * @memberOf jwsdwProductDetail.dataService
         */
        function imageData() {
            return _imageData.map(function (data, index) {
                data.index = index;
                return data;
            });
        }

        /**
         * @description Method to parse and returns the media data
         * @returns {Object} Parsed data
         * @memberOf jwsdwProductDetail.dataService
         */
        function mediaData() {
            return _mediaData;
        }

        /**
         * @description Method to parse and returns the 360 data
         * @returns {Object} Parsed data
         * @memberOf jwsdwProductDetail.dataService
         */
        function threeSixtyData() {
            return _360Data;
        }

        /**
         * @description Method to return an array of all media objects
         * @param {Boolean} [onlyImages] onlyImages
         * @returns {Object} all media
         * @memberOf jwsdwProductDetail.dataService
         */
        function allMedia(onlyImages) {
            if (onlyImages) {
                return _imageData;
            }
            return _imageData.concat(_360Data).filter(function (data) {
                return data;
            });
        }

        /**
         * @description Method to return the index of a media entry with the given id
         * @param {String} id media id
         * @returns {Number} media index
         * @memberOf jwsdwProductDetail.dataService
         */
        function mediaIndexById(id) {
            var media = allMedia(),
                i = 0;

            for (i; i < media.length; i += 1) {
                if (media[i].id === id) {
                    return i;
                }
            }
        }

        /**
         * @description Method to return the media item
         * @param {String} id media id
         * @returns {Object} media
         * @memberOf jwsdwProductDetail.dataService
         */
        function mediaById(id) {
            var media = allMedia(),
                i;

            for (i = 0; i < media.length; i++) {
                if (media[i].id === id) {
                    return media[i];
                }
            }
        }

        /**
         * @description Method to return an array of containing image and video objects
         * @returns {Object} imeges and videos
         * @memberOf jwsdwProductDetail.dataService
         */
        function _includeVideo() {
            if (_mediaData.videos && (_mediaData.videos.name || _mediaData.videos.source === 'youtube')) {
                if (!_mediaData.videos.sortOrder || _mediaData.videos.sortOrder === "") {
                    _mediaData.videos.sortOrder = "0";
                }
                return _imageData.splice(_mediaData.videos.sortOrder, 0, _mediaData.videos);
            }
        }

        /**
         * @description Method to return variation of size/color pair
         * @param {String} size Size id
         * @param {String} color Color id
         * @returns {Object} Info regarding the variant matching size and color
         * @memberOf jwsdwProductDetail.dataService
         */
        function _getVariant(size, color) {
            if (!PRODUCT_DATA || PRODUCT_DATA.length <= 0 || !PRODUCT_DATA.variants || (!size && !color)) {
                return null;
            }

            if (!color) {
                return PRODUCT_DATA.variants.find(function (variant) {
                    return variant.attributes.size.id === size;
                });
            } else if (!size) {
                return PRODUCT_DATA.variants.find(function (variant) {
                    return variant.attributes.color.id === color;
                });
            }

            return PRODUCT_DATA.variants.find(function (variant) {
                return variant.attributes.size.id === size && variant.attributes.color.id === color;
            });
        }

        /**
         * @description Method to return if color variation of a product is on sale
         * @param {String} color Color id
         * @returns {Boolean} true if color variation is on sale
         * @memberOf jwsdwProductDetail.dataService
         */
        function _isColorOnSale(color) {
            if (!PRODUCT_DATA || PRODUCT_DATA.length <= 0 || !PRODUCT_DATA.variants || !color) {
                return null;
            }

            for (let i = 0; i < PRODUCT_DATA.variants.length; i++) {
                if (PRODUCT_DATA.variants[i].attributes.color.id === color && PRODUCT_DATA.variants[i].pricing.sale !== PRODUCT_DATA.variants[i].pricing.standard) {
                    return true;
                }
            }

            return false;
        }

        /**
         * @description Method to return first available variation of size/color pair
         * @param {String} size Size id
         * @param {String} color Color id
         * @returns {Object} Info regarding the variant matching size and color
         * @memberOf jwsdwProductDetail.dataService
         */
        function _getFirstAvailableVariant(size, color) {
            if (!PRODUCT_DATA || PRODUCT_DATA.length <= 0 || !PRODUCT_DATA.variants || (!size && !color)) {
                return null;
            }

            if (!color) {
                return PRODUCT_DATA.variants.find(function (variant) {
                    return variant.attributes.size.id === size && variant.pricing.standard && variant.availability.ats > 0;
                });
            } else if (!size) {
                return PRODUCT_DATA.variants.find(function (variant) {
                    return variant.attributes.color.id === color && variant.pricing.standard && variant.availability.ats > 0;
                });
            }

            return PRODUCT_DATA.variants.find(function (variant) {
                return variant.attributes.size.id === size && variant.attributes.color.id === color;
            });
        }

        /**
         * @description Method to initialize sizes of a variant
         * @returns {void}
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _initSizes() {
            if (_variant && !_sizes) {
                _sizes = _parseSizes();
            } else if (_variant) {
                _updateSizes(_sizes);
            } else {
                _sizes = [];
            }

            _sizeGuideSizeMap = productService.getSizeMap(_sizes, PRODUCT_DATA);
        }

        /**
         * @description Method to initialize colors of a variant
         * @returns {void}
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _initColors() {
            if (_variant && !_colors) {
                _colors = _parseColors();
            } else if (_variant) {
                _updateColors(_colors);
            } else {
                _colors = [];
            }
        }

        /**
         * @description Method to parse sizes and availability from a variant
         * @returns {Object[]} size objects with id and availability
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _parseSizes() {
            var list = PRODUCT_DATA.variants
                .map(function (variant) {
                    return variant.attributes.size;
                })
                .filter(window.jwsdwUtil.arrayUtils.unique('id'))
                .sort(function (a, b) {
                    if (SIZE_SORTING_ORDER.indexOf(a.id) !== -1 &&
                        SIZE_SORTING_ORDER.indexOf(b.id) !== -1) {
                        return SIZE_SORTING_ORDER.indexOf(a.id) > SIZE_SORTING_ORDER.indexOf(b.id) ? 1 : -1;
                    }

                    let sizeA = Number(a.id);
                    let sizeB = Number(b.id);

                    if ((isNaN(sizeA) || isNaN(sizeB))) {
                        let splitSizeA = a.id.split('-');
                        let splitSizeB = b.id.split('-');

                        /* Special sorting case for socks */
                        if (splitSizeA.length === 2 && !isNaN(Number(splitSizeA[0])) && splitSizeB.length === 2 && !isNaN(Number(splitSizeB[0]))) {
                            return Number(splitSizeA[0]) - Number(splitSizeB[0]);
                        }
                    }

                    return sizeA > sizeB ? 1 : -1;
                });

            return _updateSizes(list);
        }

        /**
         * @description Method to update the size list with availability information
         * @param {Object[]} list array of size objects with size and availability
         * @returns {Object[]} array of size objects with id and updated availability
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _updateSizes(list) {
            list = list.map(function (size) {
                var variant = _getVariant(size.id, _color);

                size.display = !!variant;
                size.orderable = variant ? variant.pricing.standard && variant.availability.ats > 0 : false;
                return size;
            });

            return list;
        }

        /**
         * @description Method to parse color and availability from a variant
         * @returns {Object[]} color objects with id, name and availability
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _parseColors() {
            var list = PRODUCT_DATA.variants
                .map(function (variant) {
                    var color = variant.attributes.color;

                    color.imageUrl = window.jwsdwUtil.urlUtils.productImageUrl(variant.id, 0, service.transforms.swatch, color.name);
                    return color;
                })
                .filter(window.jwsdwUtil.arrayUtils.unique('id'));

            return _updateColors(list);
        }

        /**
         * @description Method to update the color list with availability information
         * @param {Object[]} list color objects with id, name and availability
         * @returns {Object[]} color objects with id, name and updated availability
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _updateColors(list) {
            list = list
                .map(function (color) {
                    var variant = _getFirstAvailableVariant(null, color.id) || _getVariant(null, color.id);

                    if (!variant) {
                        return null;
                    }
                    color.rgb = variant.rgb;
                    color.isBright = window.jwsdwUtil.colorUtils.isBrightColor(color.rgb);
                    color.orderable = variant ? variant.pricing.standard && variant.availability.ats > 0 : false;
                    return color;
                })
                .filter(function (color) {
                    return color;
                })
                .sort(function (firstColor, secondColor) {
                    if (_isColorOnSale(firstColor.id) && !_isColorOnSale(secondColor.id)) {
                        return 1;
                    }
                    if (!_isColorOnSale(firstColor.id) && _isColorOnSale(secondColor.id)) {
                        return -1;
                    }
                    return 0;
                });

            return list;
        }

        /**
         * @description Method to parse image data from product variation data
         * @returns {void}
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _parseImageData() {
            var colorAttribute,
                parsedData = [],
                colorData;

            if (PRODUCT_DATA.allVariations && PRODUCT_DATA.allVariations.attributes && PRODUCT_DATA.allVariations.attributes[0]) {
                colorAttribute = PRODUCT_DATA.allVariations.attributes.find(function (attribute) {
                    return attribute.id === 'color';
                });

                if (!colorAttribute) {
                    return;
                }

                colorData = colorAttribute.vals.find(function (color) {
                    return color.id === _color;
                });

                // // do nothing if image count hasn't changed
                // if (_imageData && colorData.images.meta.length === _imageData.length || !colorData) {
                //     return;
                // }

                parsedData = colorData.images.meta.map(function (image, index) {
                    return {
                        'id': 'image' + index,
                        'type': 'image',
                        'imageIndex': index,
                        'url': window.jwsdwUtil.urlUtils.imageUrl(_productId, index, service.transforms.large, 'grey6'),
                        'color': _color,
                        'specialMessage': colorData.images.specialMessage[index]
                    };
                });
            }

            _imageData = parsedData;
        }

        /**
         * @description Method to parse media data (videos, 360 videos, instructions, suspensionSystem)
         * @returns {void}
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _parseMediaData() {
            var parsedData = {},
                videoData,
                instructionData,
                suspensionData,
                videoObject;

            // add videos
            parsedData.videos = [];
            videoObject = _variant ? _variant : PRODUCT_DATA;

            if (videoObject.video && DISPLAY_VIDEO) {
                videoData = JSON.parse(videoObject.video.replace(new RegExp('\'', 'g'), '"'));
                parsedData.videos = videoData;
            }

            // add instruction
            parsedData.instruction = [];

            if (PRODUCT_DATA.instruction) {
                instructionData = JSON.parse(PRODUCT_DATA.instruction.replace(new RegExp('\'', 'g'), '"'));
                parsedData.instruction = instructionData.items.map(function (instruction, index) {
                    return {
                        'id': 'instruction' + index,
                        'type': 'instruction',
                        'title': instruction.name,
                        'description': instruction.description,
                        'url': instruction.link,
                        'thumbnail': PRODUCT_DATA.instructionThumbnail || null
                    };
                });
            }

            // add suspensionSystem information
            parsedData.suspension = [];

            if (PRODUCT_DATA.suspensionSystem) {
                suspensionData = JSON.parse(PRODUCT_DATA.suspensionSystem.replace(new RegExp('\'', 'g'), '"'));
                parsedData.suspension = suspensionData.items.map(function (suspension, index) {
                    return {
                        'id': 'suspension' + index,
                        'type': 'suspension',
                        'title': suspension.name,
                        'description': suspension.description,
                        'url': suspension.link,
                        'thumbnail': PRODUCT_DATA.suspensionSystemThumbnail || null
                    };
                });
            }

            _mediaData = parsedData;
        }

        /**
         * @description Method to parse image data from product variation data
         * @returns {void}
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _parse360Data() {
            var colorAttribute,
                parsedData = [],
                colorData;

            if (PRODUCT_DATA.allVariations && PRODUCT_DATA.allVariations.attributes && PRODUCT_DATA.allVariations.attributes[0]) {
                colorAttribute = PRODUCT_DATA.allVariations.attributes.find(function (attribute) {
                    return attribute.id === 'color';
                });

                if (!colorAttribute) {
                    return;
                }

                colorData = colorAttribute.vals.find(function (color) {
                    return color.id === _color;
                });

                // do nothing if image count hasn't changed
                if (_imageData && colorData.images['360'].length === _imageData.length || !colorData) {
                    return;
                }

                parsedData = colorData.images['360'].map(function (image, index) {
                    return {
                        'id': 'image-360-' + index,
                        'type': '360',
                        'url': image,
                        'color': _color
                    };
                }).pop();
            }

            _360Data = parsedData;
        }

        /**
         * @description Method to get initial size for a variant or alternatively for the first variant
         * @returns {String|null} size for the current variant, the first variant or null
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _initialSize() {
            var variant = _getVariant(PRODUCT_DATA.size, PRODUCT_DATA.color),
                firstVariant = PRODUCT_DATA.variants[0];

            return variant ? PRODUCT_DATA.size : (firstVariant ? firstVariant.attributes.size : null);
        }

        /**
         * @description Method to get the initial color for a product
         * @returns {String|null} color id if a variant or first variant can be found otherwise null
         * @private
         * @memberOf jwsdwProductDetail.dataService
         */
        function _initialColor() {
            var variant = _getVariant(PRODUCT_DATA.size, PRODUCT_DATA.color);
            return variant ? PRODUCT_DATA.color : PRODUCT_DATA.variants[0].attributes.color.id;
        }
    }

}(angular));
