'use strict';
(function (angular) {

    PickerController.$inject = ['$scope', '$rootScope', '$window', '$timeout', '$sce', '$log', '$http', '$q', 'settings', 'customerService', 'cookieService', 'contentService', 'locationService', 'pickupLocationService', 'productService', 'storeService', 'trackAddToBasketService', '$compile', 'SIZE_SORTING_ORDER', 'dataService'];
    angular
        .module('jwsdw.picker')
        .config(['$sanitizeProvider', function ($sanitizeProvider) {
            $sanitizeProvider.enableSvg(true);
        }])
        .controller('PickerController', PickerController);

    /**
     * @class jwsdwPicker.PickerController
     * @description Initializes the picker functionality
     * @param {Object} $scope current scope
     * @param {Object} $rootScope current root scope
     * @param {Object} $window window servbice that provides access to the window object
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} $sce angular santize service
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} settings settings
     * @param {Object} customerService customerService
     * @param {Object} cookieService cookieService
     * @param {Object} contentService service to provide content information via OCAPI
     * @param {Object} locationService service providing location functions
     * @param {Object} pickupLocationService pickupLocationService
     * @param {Object} productService service to provide product information (used for creation of variants item)
     * @param {Object} storeService storeService
     * @param {Object} trackAddToBasketService trackAddToBasketService
     * @param {Object} $compile used to compile string html to angular
     * @param {Object} SIZE_SORTING_ORDER size ids in sorting order
     * @param {Object} dataService service to provide product information
     * @returns {void}
     */
    function PickerController($scope, $rootScope, $window, $timeout, $sce, $log, $http, $q, settings, customerService, cookieService, contentService, locationService, pickupLocationService, productService, storeService, trackAddToBasketService, $compile, SIZE_SORTING_ORDER, dataService) { // eslint-disable-line max-len, max-params, max-statements
        var picker = this,
            pickerHandlers = {
                'cartPicker': cartPickerHandler,
                'basketPicker': basketPickerHandler,
                'wishlistPicker': wishlistPickerHandler,
                'expressCheckoutPicker': expressCheckoutPickerHandler,
                'loginPicker': loginPickerHandler,
                'cookieSettingsPicker': cookieSettingsPickerHandler,
                'hamburgerMenuPicker': hamburgerMenuPickerHandler,
                'infoPicker': infoPickerHandler,
                'notifyMePicker': notifyMePickerHandler,
                'pickupLocationPicker': pickupLocationPickerHandler,
                'storePicker': storePickerHandler,
                'storeServicesPicker': storeServicesPickerHandler,
                'storeAvailabilityListPicker': storeAvailabilityListPickerHandler,
                'eventPicker': eventPickerHandler,
                'personalShoppingPicker': focusSalutationSelect,
                'accountInformationPicker': focusSalutationSelect,
                'editBillingAddressPicker': focusSalutationSelect,
                'editShippingAddressPicker': editShippingAddressPickerHandler,
                'selectPickupLocationPicker': focusSalutationSelect,
                'changePasswordPicker': focusPasswordInput,
                'checkoutLoginPicker': checkoutLoginPickerHandler
            },
            pickerUpdateHandlers = {
                'cartPicker': cartPickerUpdateHandler,
                'basketPicker': basketPickerHandler
            },
            afterPickerClosedHandlers = {
                'wishlistToBasketPicker': wishlistToBasketPickerAfterCloseHandler,
                'editAddressPicker': editAddressPickerAfterCloseHandler
            },
            transforms = {
                'swatch': { 'scaleWidth': 50, 'format': 'jpg' }
            },
            _activeElement = [],
            deferredAbort;

        picker.baseUrl = settings.baseUrl || '/';
        picker.openPicker = openPicker;
        picker.closePicker = closePicker;
        picker.mediator = $window.jwsdwMediator;
        picker.giftcardPickerSetAmount = giftcardPickerSetAmount;

        $scope.picker = picker;

        $scope.picker.loginPicker = { 'callback': null };

        $scope.picker.countryPicker = { 'isVisible': false };
        $scope.picker.maintenanceInfoPicker = { 'isVisible': false };
        $scope.picker.infoPicker = { 'isVisible': false };
        $scope.picker.hamburgerMenuPicker = { 'isVisible': false };
        $scope.picker.cookieSettingsPicker = {
            'isVisible': false,
            'dntRequiredShowMore': true,
            'dntPreferencesShowMore': false,
            'dntStatisticsShowMore': false,
            'dntMarketingShowMore': false,
            'acceptAllCookies': acceptAllCookies,
            'updateCookieSettings': updateCookieSettings
        };
        $scope.picker.pickupLocationPicker = {
            'results': [],
            'loading': true,
            'selectLocation': pickupLocationSelectLocation
        };
        $scope.picker.storePicker = {
            'stores': [],
            'myStore': null,
            'loading': true,
            'selectStore': storePickerSelectStore
        };
        $scope.picker.customerPicker = {
            'customer': [],
            'selectCustomer': selectCustomer
        };

        $scope.picker.storeAvailabilityListPicker = {
            'productDataReady': false
        };

        $scope.picker.deleteCreditCardPicker = {
            'paymentMethodSpritesheet': $window.paymentMethodSpritesheet,
            'cardBrands': $window.cardBrands
        };

        /**
         * Activate function
         * @returns {void}
         */
        this.$onInit = function () {
            initCountryPicker();
            initInfoPicker();
            initStoreAvailabilityListPicker();
            initCookieSettingsPicker();
            initNotifyMePicker();

            // listen to events triggered by "outside javascript"
            $window.jwsdwMediator.subscribe('openPicker', function (type, data) {
                $timeout(function () {
                    $scope.$apply(function () {
                        openPicker(type, data);
                    });
                });
            });

            $window.jwsdwMediator.subscribe('updatePicker', function (type, data) {
                $timeout(function () {
                    $scope.$apply(function () {
                        updatePicker(type, data);
                    });
                });
            });

            $window.jwsdwMediator.subscribe('closePicker', function (type, publish) {
                $timeout(function () {
                    $scope.$apply(function () {
                        closePicker(type, publish);
                    });
                });
            });

            $window.jwsdwMediator.subscribe('afterPickerClosed', function (type) {
                $timeout(function () {
                    $scope.$apply(function () {
                        afterPickerClosed(type);
                    });
                });
            });

            $window.jwsdwMediator.subscribe('getPickerContext', function (type) {
                $timeout(function () {
                    $scope.$apply(function () {
                        exposePickerContext(type);
                    });
                });
            });

        };

        /**
         * @description Method to open picker of given type
         * @param {String} type type of picker to be opened
         * @param {Object} [data] data object
         * @returns {void}
         */
        function openPicker(type, data) {
            var pickerData = {},
                $picker = $('.jwsdw-picker[data-jwsp-picker="jwsdwPicker.' + type + '.isVisible"]');
            if (typeof data !== 'undefined') {
                // create new object to avoid changes on references object
                pickerData = angular.extend({}, data);
            }
            pickerData.isVisible = true;

            requestAnimationFrame(() => {
                $picker.addClass('jwsdw-picker-flex');
                requestAnimationFrame(() => {
                    $picker.addClass('jwsdw-show');
                    $picker.one('transitionend', () => $window.jwsdwMediator.publish('afterPickerOpen', type));
                });
            });

            $window.jwsdwMediator.publish('openOverlay', type);

            $log.debug('Open Picker with type', type);
            $log.debug('Picker Data', pickerData);

            $scope.picker[type] = angular.extend({}, $scope.picker[type], pickerData);

            // focus for screenreaders and keyboard accessibility
            _activeElement.push(document.activeElement);
            setTimeout(function () {
                if (type === 'cartPicker') {
                    $picker.find('.jwsdw-button-toCart').trigger('focus');
                } else if ($picker.find('.jwsdw-pickerBackButton').length > 0) {
                    $picker.find('.jwsdw-pickerBackButton').trigger('focus');
                } else {
                    $picker.trigger('focus');
                }
            });
            if (pickerHandlers[type]) {
                pickerHandlers[type](pickerData, type);
            }
        }

        /**
         * @description Method to update picker of given type
         * @param {String} type type of picker to be updated
         * @param {Object} [data] data object
         * @returns {void}
         */
        function updatePicker(type, data) {
            if (!data) {
                return;
            }

            $log.debug('Update Picker with type', type);
            $log.debug('Picker Data', data);

            $scope.picker[type] = angular.extend({}, $scope.picker[type], data);
            if (pickerUpdateHandlers[type]) {
                pickerUpdateHandlers[type](data);
            }
        }

        /**
         * @description Method to close picker of given type
         * @param {String} type type of the picker to close
         * @param {Boolean} [publish] true if close event is published false otherwise
         * @returns {void}
         */
        function closePicker(type, publish) {
            var $picker = $('.jwsdw-picker[data-jwsp-picker="jwsdwPicker.' + type + '.isVisible"]');

            if (type && type === '3in1configurator') {
                $window.jwsdwMediator.publish('closeOverlay', type);
                $('.jwsdw-3in1configurator').addClass('jws-hidden');
            }

            $picker.one("transitionend", () => {
                $picker.removeClass('jwsdw-picker-flex');
                $window.jwsdwMediator.publish('afterPickerClosed', type);
            });

            $picker.removeClass('jwsdw-show');

            if (!$scope.picker[type] || !$scope.picker[type].isVisible) {
                return;
            }
            if (type === 'expressCheckoutPicker') {
                legacyBasket();
            }
            if (type === 'countryPicker') {
                if (event) {
                    let targetElement = event.target;
                    let isCurrentCountry = targetElement.getAttribute('data-current-country');
                    let countryCode = targetElement.getAttribute('data-country-code');
                    if (!isCurrentCountry) {
                        gtmEventCloseCountryPicker();
                    } else {
                        gtmEventCurrentCountrySelect(countryCode);
                    }
                } else {
                    gtmEventCloseCountryPicker();
                }
            }
            $scope.picker[type].isVisible = false;
            if (typeof publish === 'undefined' || publish === true) {
                $window.jwsdwMediator.publish('closeOverlay', type);
            }

            // focus for screenreaders and keyboard accessibility
            _activeElement.pop().focus();
        }

        /**
         * @description Method which handles the event when a picker finishes its closing animation.
         * @param {String} type type of the picker to close
         * @returns {undefined}
         */
        function afterPickerClosed(type) {
            if (afterPickerClosedHandlers[type]) {
                afterPickerClosedHandlers[type]();
            }
        }

        /**
         * @description Method push gtm event on locale selection.
         * @returns {undefined}
         */
        function gtmEventCloseCountryPicker() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ce.misc',
                'eventCategory': 'Countrypicker',
                'eventAction': 'close_country',
                'eventLabel': undefined,
                'eventValue': undefined,
                'nonInteraction': false
            });
        }

        /**
         * @description Method push gtm event on locale selection.
         * @param {String} countryCode - Current country code.
         * @returns {undefined}
         */
        function gtmEventCurrentCountrySelect(countryCode) {
            let url = window.location.href;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ce.misc',
                'eventCategory': 'Countrypicker',
                'eventAction': 'choose_country',
                'eventLabel': countryCode,
                'eventValue': undefined,
                'nonInteraction': false,
                'selection': url
            });
        }

        /**
         * @description Method which publishes a picker context change so that the data can be used outside of the angular scope.
         * @param {String} type - The picker to expose context from.
         * @returns {undefined}
         */
        function exposePickerContext(type) {
            $window.jwsdwMediator.publish(`exposePickerContext.${type}`, $scope.picker[type]);
        }

        /**
         * @description Method to reset legacy basket on session
         * @returns {void}
         */
        function legacyBasket() {
            $http({
                'method': 'GET',
                'url': window.jwsdwSettings.baseUrl + '/Cart-HandleLegacyBasket?ajax=true'
            }).then(success, error);

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                $window.jwsdwMediator.publish('setCartCount', response.data);
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function error(err) {
                $log.error('Could not fetch basket ', err);
            }
        }

        /**
         * Method initialize shop picker
         * @returns {void}
         */
        function initCountryPicker() {
            let cookieName = 'isFavoriteShop' + (settings.countryCode === 'INT' ? 'INT' : ''),
                expirationTime = settings.countryCode === 'INT' ? 5 : 365 * 24 * 60;

            // check if shop picker needs to be shown
            // find more information on this logic: https://confluence.jack-wolfskin.com/display/ECOMDEV/Localization
            if (settings.recommendShops && (!$window.jwsdwUtil.getCookie(cookieName) || (settings.countryCode === 'INT' && window.pageType === 'Home'))) {
                $timeout(openPicker, 0, false, 'countryPicker', { 'mode': 'recommended', 'level': 2 });
            }

            // listen to closing the shop picker
            $scope.$watch('picker.countryPicker.isVisible', function (isVisible, wasVisible) {
                if (wasVisible && !isVisible) {
                    $window.jwsdwUtil.setCookie(
                        cookieName,
                        'true',
                        expirationTime
                    );
                }
            });
        }

        /**
         * Method initialize shop picker
         * @returns {void}
         */
        function initNotifyMePicker() {
            // listen to opening the picker
            $scope.$watch('picker.notifyMePicker.isVisible', function (isVisible, wasVisible) {
                if (!wasVisible && isVisible) {
                    $('#jwsdw-notifyMePicker').find('.jws-success-view').addClass('jws-hidden');
                    $('#jwsdw-notifyMePicker').find('.jws-initial-view').removeClass('jws-hidden');
                }
            });
        }

        /**
         * Method initialize shop picker
         * @returns {void}
         */
        function initInfoPicker() {
            // listen to closing the info picker
            $scope.$watch('picker.infoPicker', function (newInfoPicker, oldInfoPicker) {
                if (!newInfoPicker.isVisible && oldInfoPicker.isVisible) {
                    if (oldInfoPicker.ocapi) {
                        contentService.cancelGetContent();
                    } else if (deferredAbort && deferredAbort.promise) {
                        deferredAbort.resolve();
                    }
                }
            }, true);
        }

        /**
         * Method initialize StoreAvailabilityList Picker
         * @returns {void}
         */
        function initStoreAvailabilityListPicker() {
            $scope.$watchGroup([
                'picker.storeAvailabilityListPicker.selectedSize',
                'picker.storeAvailabilityListPicker.selectedColor'
            ], function () {
                var sizeId,
                    colorId,
                    selectedVariant;

                if (!$scope.picker.storeAvailabilityListPicker.productDataReady) {
                    return;
                }

                dataService.update();
                $scope.picker.storeAvailabilityListPicker.sizes = dataService.sizes();
                $scope.picker.storeAvailabilityListPicker.colors = dataService.colors();

                sizeId = $scope.picker.storeAvailabilityListPicker.selectedSize.id;
                colorId = $scope.picker.storeAvailabilityListPicker.selectedColor.id;

                selectedVariant = $scope.picker.storeAvailabilityListPicker.selectedVariant = $scope.picker.storeAvailabilityListPicker.variants
                    .filter(function (variation) {
                        return variation.attributes.size.id === sizeId && variation.attributes.color.id === colorId;
                    })[0] || $scope.picker.storeAvailabilityListPicker.selectedVariant;

                $scope.picker.storeAvailabilityListPicker.productId = selectedVariant.id;
            });
        }

        /**
         * @description Method to focus the salutation field
         * @param {Object} data unused picker data
         * @param {String} type picker type
         * @returns {void}
         */
        function focusSalutationSelect(data, type) {
            var input = document.querySelector('#jwsdw-' + type + ' .jws-input[name=salutation]');
            input.focus();
        }

        /**
         * @description Method to focus the password field
         * @param {Object} data unused picker data
         * @param {String} type picker type
         * @returns {void}
         */
        function focusPasswordInput(data, type) {
            var input = document.querySelector('#jwsdw-' + type + ' .jws-input[name=password]');
            input.focus();
        }

        /**
         * @description Method to focus the postal code or city input field
         * @param {Object} data the picker data
         * @param {String} type the picker type
         * @returns {void}
         */
        function focusPostalCodeOrCityInput(data, type) {
            var input = document.querySelector('#jwsdw-' + type + ' .jws-input[name=postalCodeOrCity]');
            input.focus();
        }

        /**
         * @description Method to handle loginPicker specific logic
         * @returns {void}
         */
        function loginPickerHandler() {
            // update exposed object
            window.jwsdwLoginPicker = $scope.picker.loginPicker;
        }

        /**
         * @description Method to handle cartPicker specific logic
         * @returns {void}
         */
        function cartPickerHandler() {
            $scope.picker.cartPicker.addToCartSuccessful = false;
        }

        /**
         * @description Method to handle basketPicker specific logic
         * @returns {void}
         */
        function basketPickerHandler() {
            if ($scope.picker.basketPicker.update) {
                $http({
                    'method': 'GET',
                    'url': window.jwsdwSettings.baseUrl + '/Cart-getBasket?source=minibasket'
                }).then(success, error);

                /**
                 * Method called on success
                 * @param {Object} response response object
                 * @returns {void}
                 */
                function success(response) {
                    window.jwsdwMediator.publish('basketPicker:retrieved', response.data);
                }

                /**
                 * Method called on error
                 * @param {Object} err error object
                 * @returns {void}
                 */
                function error(err) {
                    $log.error('Could not fetch picker ', err);
                }
            }
        }

        /**
         * @description Method to handle checkoutLoginPicker specific logic
         * @returns {void}
         */
        function checkoutLoginPickerHandler() {
            if ($scope.picker.checkoutLoginPicker.fetchContent) {
                let url = window.jwsdwSettings.baseUrl + '/Pickers-checkoutLoginPickerContent',
                    loadJqueryValidation = !window.jwsdwSettings.jQueryValidationLoaded;

                if (loadJqueryValidation) {
                    url += `?loadJqueryValidation=${loadJqueryValidation}`;
                }

                $http({
                    'method': 'GET',
                    'url': url
                }).then(success, error);

                /**
                 * Method called on success
                 * @param {Object} response response object
                 * @returns {void}
                 */
                function success(response) {
                    window.jwsdwMediator.publish('checkoutLoginPicker:retrieved', response.data);

                    if (loadJqueryValidation) {
                        window.jwsdwSettings.jQueryValidationLoaded = true;
                    }
                }

                /**
                 * Method called on error
                 * @param {Object} err error object
                 * @returns {void}
                 */
                function error(err) {
                    $log.error('Could not fetch picker ', err);
                }
            }
        }

        /**
         * @description Method to handle cartPicker specific logic
         * @returns {void}
         */
        function wishlistPickerHandler() {
            var oldLayer = window.dynamic_tm_data;
            var product = $scope.picker.wishlistPicker.product;

            window.dataLayer.push({
                'event': 'eec.addToWishlist',
                'eventCategory': 'ecommerce',
                'eventAction': 'add to wishlist',
                'eventLabel': 'wishlist',
                'eventValue': product.price,
                'nonInteraction': false,
                'ecommerce': {
                    'currencyCode': oldLayer.currency,
                    'add': {
                        'actionField': {
                            'list': 'wishlist'
                        },
                        'products': [{
                            'name': product.name.replace(/&amp;/g, '&'),
                            'id': product.id,
                            'price': product.price,
                            'brand': 'Jack Wolfskin GmbH & Co. KGaA',
                            'category': product.category,
                            'color': product.color,
                            'quantity': 1,
                            'dimension13': product.id.split('_').shift(),
                            'dimension14': product.category
                        }]
                    }
                }
            });
        }

        /**
         * @description Method to handle expressCheckoutPicker specific logic
         * @returns {void}
         */
        function expressCheckoutPickerHandler() {
            $scope.picker.expressCheckoutPicker.addToCartSuccessful = false;
            if ($scope.picker.expressCheckoutPicker.content) {
                return;
            }

            $http({
                'method': 'GET',
                'url': window.jwsdwSettings.baseUrl + '/Pickers-expressCheckoutPicker'
            }).then(success, error);

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                $scope.picker.expressCheckoutPicker.content = response.data;
                $('#jwsdw-expressCheckout-Details').append(response.data);
                $compile($('#jwsdw-expressCheckout-Details').contents())($scope);
                window.jwsdwMediator.publish('updateVerticalScroll');
                $.getScript("https://pay.google.com/gp/p/js/pay.js", function () {
                    window.googlePay.onLoaded();
                });
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function error(err) {
                $log.error('Could not fetch picker ', err);
            }
        }

        /**
         * @description Method to handle cartPicker specific update logic
         * @param {Object} data data object with picker specific data
         * @returns {void}
         */
        function cartPickerUpdateHandler(data) {
            $window.jwsdwMediator.publish('cartPickerUpdated', data);
            trackAddToBasketService.send(data);
        }

        /**
         * @description Method top open cookie settings picker
         * @returns {void}
         */
        function cookieSettingsPickerHandler() {
            var preferencesCookie = window.jwsdwUtil.getCookie('jwsdw-cookiePreferences-dntPreferences'),
                statisticsCookie = window.jwsdwUtil.getCookie('jwsdw-cookiePreferences-dntStatistics'),
                marketingCookie = window.jwsdwUtil.getCookie('jwsdw-cookiePreferences-dntMarketing');

            $scope.picker.cookieSettingsPicker.dntPreferences = preferencesCookie ? parseInt(preferencesCookie) : 1;
            $scope.picker.cookieSettingsPicker.dntStatistics = statisticsCookie ? parseInt(statisticsCookie) : 1;
            $scope.picker.cookieSettingsPicker.dntMarketing = marketingCookie ? parseInt(marketingCookie) : 1;
        }

        /**
         * @description Method initialize cookie settings picker
         * @returns {void}
         */
        function initCookieSettingsPicker() {
            var statisticsCookie = window.jwsdwUtil.getCookie('jwsdw-cookiePreferences-dntStatistics'),
                marketingCookie = window.jwsdwUtil.getCookie('jwsdw-cookiePreferences-dntMarketing'),
                dntStatistics = statisticsCookie ? parseInt(statisticsCookie) : 1,
                dntMarketing = marketingCookie ? parseInt(marketingCookie) : 1;

            if (dntStatistics && dntMarketing) {
                $scope.picker.cookieSettingsPicker.trackingInactive = true;
            }
        }

        /**
         * @description Method to handle eventPicker specific logic
         * @param {Object} data data object with picker specific data
         * @param {String} data.eventId product name
         * @param {String} data.type event type
         * @param {String} data.headline event headline
         * @param {String} data.image event image
         * @param {String} data.date event date as localized string
         * @param {String} data.start event start time as localized string
         * @param {String} data.end event end time as localized string
         * @param {String} data.storeName store name time as string
         * @param {String} data.address event address time as html string
         * @returns {void}
         */
        function eventPickerHandler(data) {
            angular.extend($scope.picker.eventPicker, data);
            $scope.picker.eventPicker.image = $sce.trustAsResourceUrl(data.image);
            $scope.picker.eventPicker.description = $sce.trustAsHtml(data.description);
            $scope.picker.eventPicker.address = $sce.trustAsHtml(data.address);
        }

        /**
         * @description Method to handle closing editShippingAddressPickerHandler specific logic
         * @param {Object} data data object with picker specific data
         * @param {String} [type] picker type
         * @returns {void}
         */
        function editShippingAddressPickerHandler(data, type) {
            $scope.picker.editShippingAddressPicker = data;
            $scope.picker.editShippingAddressPicker.isVisible = true;
            $scope.picker.editShippingAddressPicker.setPreferred = false;

            focusSalutationSelect(data, type);
        }

        /**
         * @description Method to handle storeAvailabilityListPicker specific logic
         * @param {Object} data data object with picker specific data
         * @param {String} data.productId product id
         * @param {String} type picker type
         * @returns {void}
         */
        function storeAvailabilityListPickerHandler(data, type) {
            window.jwsdwMediator.publish('clearLocation');

            $scope.picker.storeAvailabilityListPicker.productDataReady = false;

            if (data.variants) {
                _parseVariants(data.variants);
            } else {
                productService.getVariantsJSON(data.productId).then(_parseVariants);
            }

            setTimeout(function () {
                focusPostalCodeOrCityInput(data, type);
            });

            /**
             * @description Method to parse variants and populate storeAvailabilityListPicker
             * @param {Object[]} variants product variants
             * @returns {void}
             */
            function _parseVariants(variants) {
                var selectedVariant = variants.find(function (variant) {
                    return variant.id === data.productId;
                });

                $scope.picker.storeAvailabilityListPicker.productDataReady = true;
                $scope.picker.storeAvailabilityListPicker.variants = variants;

                $scope.picker.storeAvailabilityListPicker.colors = variants
                    .map(function (variant) {
                        return {
                            'id': variant.id,
                            'color': variant.attributes.color
                        };
                    })
                    .map(function (variant) {
                        var color = variant.color;
                        color.imageUrl = window.jwsdwUtil.urlUtils.imageUrl(variant.id, 0, transforms.swatch, 'grey5');
                        return color;
                    })
                    .filter(window.jwsdwUtil.arrayUtils.unique('id'));
                $scope.picker.storeAvailabilityListPicker.selectedColor = selectedVariant.attributes.color;

                $scope.picker.storeAvailabilityListPicker.sizes = variants
                    .map(function (variant) {
                        return variant.attributes.size;
                    })
                    .filter(window.jwsdwUtil.arrayUtils.unique('id'))
                    .sort(function (a, b) {
                        if (SIZE_SORTING_ORDER.indexOf(a.id) !== -1 &&
                            SIZE_SORTING_ORDER.indexOf(b.id) !== -1) {
                            return SIZE_SORTING_ORDER.indexOf(a.id) > SIZE_SORTING_ORDER.indexOf(b.id) ? 1 : -1;
                        }
                        return Number(a.id) > Number(b.id) ? 1 : -1;
                    });
                $scope.picker.storeAvailabilityListPicker.selectedSize = selectedVariant.attributes.size;
            }
        }

        /**
         * @description Method to handle infoPicker specific logic
         * @param {Object} data data object with picker specific data
         * @param {String} data.cid content asset id
         * @param {String} data.url content asset url
         * @returns {void}
         */
        function infoPickerHandler(data) {
            if (data.hasOwnProperty('content') && data.content) {
                $scope.picker.infoPicker = data;
                $scope.picker.infoPicker.content = $sce.trustAsHtml(data.content);

                if (data.headline) {
                    $scope.picker.infoPicker.showHeadline = true;
                }
            } else if (data.cid) {
                $scope.picker.infoPicker = {
                    'isVisible': true,
                    'width': $scope.picker.infoPicker.width,
                    'level': $scope.picker.infoPicker.level,
                    'ocapi': true,
                    'verticalRhythm': $scope.picker.infoPicker.verticalRhythm
                };
                contentService.getContent(data.cid).then(successOCAPI, errorOCAPI);
            } else {
                if (data.cgid && !data.url) {
                    data.url = data.cgid;
                }
                $scope.picker.infoPicker = {
                    'isVisible': true,
                    'width': $scope.picker.infoPicker.width,
                    'level': $scope.picker.infoPicker.level
                };
                deferredAbort = $q.defer();
                $scope.picker.infoPicker.ocapi = false;
                $http({
                    'method': 'GET',
                    'url': data.url,
                    'params': {
                        'format': 'ajax'
                    },
                    'timeout': deferredAbort.promise
                }).then(successAJAX, errorAJAX);
            }

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function successOCAPI(response) {
                $scope.picker.infoPicker = data;
                $scope.picker.infoPicker.headline = response.headline;
                $scope.picker.infoPicker.image = $sce.trustAsResourceUrl(response.image);
                $scope.picker.infoPicker.content = $sce.trustAsHtml(response.content);

                if (response.headline) {
                    $scope.picker.infoPicker.showHeadline = true;
                }
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function errorOCAPI(err) {
                $log.error('Could not fetch content asset ' + data.cid, err);
            }

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function successAJAX(response) {
                $scope.picker.infoPicker = data;
                $scope.picker.infoPicker.content = $sce.trustAsHtml(response.data);
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function errorAJAX(err) {
                $log.error('Could not fetch content asset ' + data.url, err);
            }
        }

        /**
         * @description Method to handle notifyMePicker specific logic
         * @param {Object} data data object with picker specific data
         * @param {String} data.cid content asset id
         * @param {String} data.url content asset url
         * @returns {void}
         */
        function notifyMePickerHandler(data) {
            if (data.hasOwnProperty('productID') && data.productID) {
                $scope.picker.notifyMePicker = data;
                $scope.picker.notifyMePicker.productID = $sce.trustAsHtml(data.productID);
                $scope.picker.notifyMePicker.productName = $sce.trustAsHtml(data.productName);
                $scope.picker.notifyMePicker.productSize = $sce.trustAsHtml(data.productSize);
                $scope.picker.notifyMePicker.productColor = $sce.trustAsHtml(data.productColor);
                $scope.picker.notifyMePicker.image = $sce.trustAsHtml(data.image);
            } else if (data.cid) {
                $scope.picker.notifyMePicker = {
                    'isVisible': true,
                    'width': $scope.picker.notifyMePicker.width,
                    'level': $scope.picker.notifyMePicker.level,
                    'ocapi': true
                };
                contentService.getContent(data.cid).then(successOCAPI, errorOCAPI);
            } else {
                $scope.picker.notifyMePicker = {
                    'isVisible': true,
                    'width': $scope.picker.notifyMePicker.width,
                    'level': $scope.picker.notifyMePicker.level
                };
                deferredAbort = $q.defer();
                $scope.picker.notifyMePicker.ocapi = false;
                $http({
                    'method': 'GET',
                    'url': data.url,
                    'params': {
                        'format': 'ajax'
                    },
                    'timeout': deferredAbort.promise
                }).then(successAJAX, errorAJAX);
            }

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function successOCAPI(response) {
                $scope.picker.notifyMePicker = data;
                $scope.picker.notifyMePicker.headline = response.headline;
                $scope.picker.notifyMePicker.image = $sce.trustAsResourceUrl(response.image);
                $scope.picker.notifyMePicker.content = $sce.trustAsHtml(response.content);

                if (response.headline) {
                    $scope.picker.notifyMePicker.showHeadline = true;
                }
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function errorOCAPI(err) {
                $log.error('Could not fetch content asset ' + data.cid, err);
            }

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function successAJAX(response) {
                $scope.picker.notifyMePicker = data;
                $scope.picker.notifyMePicker.content = $sce.trustAsHtml(response.data);
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function errorAJAX(err) {
                $log.error('Could not fetch content asset ' + data.url, err);
            }
        }

        /**
         * @description Method to handle hamburgerMenuPicker specific logic
         * @returns {void}
         */
        function hamburgerMenuPickerHandler() {
            if ($scope.picker.hamburgerMenuPicker.content) {
                return;
            }


            $http({
                'method': 'GET',
                'url': window.jwsdwSettings.baseUrl + '/Pickers-hamburgerMenuPickerNavigation'
            }).then(success, error);

            /**
             * Method called on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                var activeCheckbox = document.querySelector('.jwsdw-header-TopLevelTabInput:checked'),
                    activeCategory = activeCheckbox ? activeCheckbox.id : null;

                $scope.picker.hamburgerMenuPicker.content = response.data;
                document.getElementById('jwsdw-hamburgerMenu-navigation').innerHTML = response.data;
                if (activeCategory) {
                    document.getElementById(activeCategory).checked = true;
                }
            }

            /**
             * Method called on error
             * @param {Object} err error object
             * @returns {void}
             */
            function error(err) {
                $log.error('Could not fetch navigation ', err);
            }
        }

        /**
         * Method to handle storePicker specific logic
         * @returns {void} nothing
         */
        function storePickerHandler() {
            var postalCodeOrCity = $scope.picker.storePicker.postalCodeOrCity,
                locatePosition = $scope.picker.storePicker.locatePosition;

            $scope.picker.storePicker.error = false;
            $scope.picker.storePicker.stores = [];
            $scope.picker.storePicker.myStore = null;
            $scope.picker.storePicker.loading = true;

            if (locatePosition) {
                locationService.geolocateAdress().then(locateSuccess, errorCallback);
            } else {
                storeService.searchByAddress(postalCodeOrCity, true, $scope.picker.storePicker.service)
                    .then(successCallback, errorCallback);
            }

            /**
             * Method called on success
             * @param {object} position position object
             * @returns {void} nothing
             */
            function locateSuccess(position) {
                storeService.searchByLocation(position.lat, position.lng, true, $scope.picker.storePicker.service)
                    .then(successCallback, errorCallback);
            }

            /**
             * @description Function on success
             * @param {object} result response object
             * @return {void} nothing
             */
            function successCallback(result) {
                $scope.picker.storePicker.loading = false;

                if (!result) {
                    return;
                }
                $scope.picker.storePicker.stores = result.stores.stores;

                $scope.picker.storePicker.myStore = result.stores.myStore;
            }

            /**
             * @description Method on error
             * @param {Object} error the error object
             * @return {void} nothing
             */
            function errorCallback(error) {
                $scope.picker.storePicker.stores = [];
                $scope.picker.storePicker.myStore = null;
                $scope.picker.storePicker.loading = false;
                $scope.picker.storePicker.error = {
                    'errorCode': error.code || error
                };
            }
        }

        /**
         * Method to handle storeServicesPicker specific logic
         * @returns {void} nothing
         */
        function storeServicesPickerHandler() {
            $scope.picker.storeServicesPicker.promotions = $scope.picker.storeServicesPicker.promotions.filter(function (promo) {
                return promo.picture;
            });
            $scope.picker.storeServicesPicker.jackWolfskinCard = $scope.picker.storeServicesPicker.services.find(function (service) {
                return service.id === 'jackWolfskinCard';
            });
            $scope.picker.storeServicesPicker.jackWolfskinCard.description = $sce.trustAsHtml($scope.picker.storeServicesPicker.jackWolfskinCard.description);
            $scope.picker.storeServicesPicker.services = $scope.picker.storeServicesPicker.services.filter(function (service) {
                return service.id !== 'jackWolfskinCard';
            });
        }

        /**
         * @description Method to select store
         * @param {object} store selected store
         * @returns {void} nothing
         */
        function storePickerSelectStore(store) {
            $window.jwsdwMediator.publish('storePickerSelectStore', {
                'store': store
            });

            if ($scope.picker.storePicker && $scope.picker.storePicker.isVisible) {
                closePicker('storePicker', true);
            }
            if ($scope.picker.searchMyStorePicker && $scope.picker.searchMyStorePicker.isVisible) {
                closePicker('searchMyStorePicker', true);
            }
        }

        /**
         * @description Method to select customer
         * @param {object} customer selected customer
         * @returns {void} nothing
         */
        function selectCustomer(customer) {
            if (!customer.isEnabled) {
                return;
            }
            $window.jwsdwMediator.publish('selectCustomer', customer);
            closePicker('customerPicker', true);
        }

        /**
         * Method to handle pickupLocationPicker specific logic
         * @returns {void} nothing
         */
        function pickupLocationPickerHandler() {
            var locatePosition = $scope.picker.pickupLocationPicker.locatePosition,
                postalCodeOrCity = $scope.picker.pickupLocationPicker.postalCodeOrCity;

            $scope.picker.pickupLocationPicker.error = false;
            $scope.picker.pickupLocationPicker.items = [];

            if (locatePosition) {
                pickupLocationPickerLocate();
            } else if (postalCodeOrCity) {
                pickupLocationPickerSearch();
            }
        }

        /**
         * @description Method to search for pickuplocations
         * @returns {void}
         */
        function pickupLocationPickerSearch() {
            var locationType = $scope.picker.pickupLocationPicker.locationType,
                postalCodeOrCity = $scope.picker.pickupLocationPicker.postalCodeOrCity;

            $scope.picker.pickupLocationPicker.loading = true;

            pickupLocationService.getLocationsByAddress(postalCodeOrCity, locationType).then(successCallback, errorCallback);

            /**
             * Function on success
             * @param {object} data response object
             * @return {void} nothing
             */
            function successCallback(data) {
                $scope.picker.pickupLocationPicker.items = data.hasOwnProperty('pickupLocations') ? data.pickupLocations : [];
                $scope.picker.pickupLocationPicker.loading = false;
            }

            /**
             * @description Method on error
             * @param {Object} error the error object
             * @return {void} nothing
             */
            function errorCallback(error) {
                $scope.picker.pickupLocationPicker.items = [];
                $scope.picker.pickupLocationPicker.error = {
                    'errorCode': error.code
                };
                $scope.picker.pickupLocationPicker.loading = false;
            }
        }

        /**
         * @description Method to locate user and search for pickuplocations
         * @returns {void}
         */
        function pickupLocationPickerLocate() {
            var locationType = $scope.picker.pickupLocationPicker.locationType;

            locationService.geolocateAdress().then(locateSuccess, errorCallback);

            $scope.picker.pickupLocationPicker.loading = true;

            /**
             * Method called on success
             * @param {object} position position object
             * @returns {void} nothing
             */
            function locateSuccess(position) {
                pickupLocationService.getLocationsByCoordinate(position.lat, position.lng, locationType).then(successCallback, errorCallback);
            }

            /**
             * Function on success
             * @param {object} data response object
             * @return {void} nothing
             */
            function successCallback(data) {
                $scope.picker.pickupLocationPicker.items = data.hasOwnProperty('pickupLocations') ? data.pickupLocations : [];
                $scope.picker.pickupLocationPicker.loading = false;
            }

            /**
             * @description Method on error
             * @param {Object} error the error object
             * @return {void} nothing
             */
            function errorCallback(error) {
                $scope.picker.pickupLocationPicker.items = [];
                $scope.picker.pickupLocationPicker.error = {
                    'errorCode': error.code || error
                };
                $scope.picker.pickupLocationPicker.loading = false;
            }
        }

        /**
         * @descriptionMethod to trigger broadcast event for selected location
         * @param {object} location pickuplocation object
         * @returns {void} nothing
         */
        function pickupLocationSelectLocation(location) {
            var data = angular.extend({}, $scope.picker.pickupLocationPicker, {
                'selectedLocation': location
            });

            $window.jwsdwMediator.publish('pickupLocationSelectLocation', data);
            closePicker('pickupLocationPicker', true);
        }

        /**
         * @description Method to save all cookie settings
         * @param {Boolean} close if true, close picker
         * @returns {void}
         */
        function acceptAllCookies(close) {
            $scope.picker.cookieSettingsPicker.dntPreferences = 0;
            $scope.picker.cookieSettingsPicker.dntStatistics = 0;
            $scope.picker.cookieSettingsPicker.dntMarketing = 0;

            updateCookieSettings(close);
        }

        /**
         * @description Method to save cookie settings
         * @param {Boolean} close if true, close picker
         * @returns {void}
         */
        function updateCookieSettings(close) {
            var expirationTime = 5 * 365 * 24 * 60;

            $scope.picker.cookieSettingsPicker.requestActive = true;
            $scope.picker.cookieSettingsPicker.requestError = false;

            window.jwsdwUtil.setCookie(
                'jwsdw-cookieUsageAcknowledged',
                'true',
                expirationTime,
                'Lax'
            );

            cookieService.updateCookieSettings(
                $scope.picker.cookieSettingsPicker.dntPreferences,
                $scope.picker.cookieSettingsPicker.dntStatistics,
                $scope.picker.cookieSettingsPicker.dntMarketing
            ).then(success, error);

            /**
             * Function on success
             * @returns {void}
             */
            function success() {
                $scope.picker.cookieSettingsPicker.requestActive = false;
                $scope.picker.cookieSettingsPicker.isVisible = false;

                if (close) {
                    $window.jwsdwMediator.publish('closeOverlay', 'cookieSettingsPicker');
                }

                if ($scope.picker.cookieSettingsPicker.trackingInactive &&
                    (!$scope.picker.cookieSettingsPicker.dntStatistics ||
                        !$scope.picker.cookieSettingsPicker.dntMarketing ||
                        !$scope.picker.cookieSettingsPicker.dntPreferences)) {
                    $scope.picker.cookieSettingsPicker.trackingInactive = false;
                    $window.jwsdwApi.tracking.loadTrackingScripts();
                }
            }

            /**
             * @description Function on error
             * @returns {void}
             */
            function error() {
                $scope.picker.cookieSettingsPicker.requestActive = false;
                $scope.picker.cookieSettingsPicker.requestError = true;
            }
        }

        /**
         * @description Handler for wishlist picker after close event.
         * @returns {undefined}
         */
        function wishlistToBasketPickerAfterCloseHandler() {
            $scope.picker.wishlistToBasketPicker.product = null;
            $scope.picker.wishlistToBasketPicker.sizeSwatches = [];
        }

        /**
         * @description Method to handle giftcard amount
         * @returns {void}
         */
        function giftcardPickerSetAmount() {
            let form = $scope.giftcardForm;
            let giftcardAmount = $scope.picker.giftcardpricing;
            if (giftcardAmount && form.$valid) {
                $window.jwsdwMediator.publish('setGiftcardAmount', giftcardAmount);
                closePicker('giftcardAmountPicker', true);
            }
        }

        /**
         * @description Handler for edit address picker after close event.
         * @returns {undefined}
         */
        function editAddressPickerAfterCloseHandler() {
            $scope.picker.editAddressPicker = {};
        }
    }
}(angular));
