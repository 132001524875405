'use strict';

(function (angular, window) {

    angular
        .module('jwsdw.services')
        .constant('LIST_PRICE_BOOK', window.jwsdwSettings.listPriceBook)
        .constant('SALE_PRICE_BOOK', window.jwsdwSettings.salePriceBook)
        .constant('BASE_URL', window.jwsdwSettings.baseUrl)
        .constant('REGION', window.jwsdwSettings.countryCode)
        .constant('COUNTRYCODE', window.jwsdwSettings.countryCode)
        .constant('REQUESTTOKEN', window.jwsdwSettings.requestToken)
        .constant('IMAGE_BASE_URL', window.jwsdwSettings.productGetImageBaseUrl)
        .constant('GIFTCARDS', window.jwsdwSettings.giftCards)
        .constant('SIZEGUIDE', window.jwsdwSizeGuide)
        .constant('SIZE_SORTING_ORDER', window.jwsdwSettings.sizeSortingOrder)
        .constant('PRODUCT_DATA', window.jwsdwProductDetailSettings ? window.jwsdwProductDetailSettings.data : {})
        .constant('BAZAARVOICE_ACTIVE', window.jwsdwProductDetailSettings ? window.jwsdwProductDetailSettings.isBazaarVoiceActive : false)
        .constant('DISPLAY_VIDEO', window.jwsdwProductDetailSettings ? window.jwsdwProductDetailSettings.displayVideo : false);
}(angular, window));
