'use strict';

(function (angular) {
    basketService.$inject = ['$q', '$window', '$log', '$http', 'ocapiBasketService', 'trackAddToBasketService', 'GIFTCARDS', 'BASE_URL'];
    angular
        .module('jwsdw.services')
        .factory('basketService', basketService);

    /**
     * @class jwsdwServices.basketService
     * @description Service to provide access functions to the basket information.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @param {object} $http http service that provides an API for ajax calls
     * @param {Object} ocapiBasketService service to provide access to the ocapi basket resource
     * @param {Object} trackAddToBasketService service to provide tracking functionality for the basket
     * @param {String[]} GIFTCARDS coma separated list of giftcard master IDs
     * @param {string} BASE_URL base url for service requests
     * @returns {Object} service object that returns public methods
     */
    function basketService($q, $window, $log, $http, ocapiBasketService, trackAddToBasketService, GIFTCARDS, BASE_URL) {
        var _basketData,
            service;

        service = {
            'getBasket': getBasket,
            'addItem': addItem,
            'addBonusItem': addBonusItem,
            'addItems': addItems,
            'removeItem': removeItem,
            'updateQuantity': updateQuantity,
            'addCoupon': addCoupon,
            'removeCoupon': removeCoupon,
            'removeBonusCoupon': removeBonusCoupon,
            'parseBasketData': parseBasketData,
            'prepareCheckout': prepareCheckout,
            'checkout': checkout,
            'directCheckout': directCheckout
        };

        return service;

        /**
         * @description Method to get the basket.
         * @returns {Promise} promise with the parsed basket data
         */
        function getBasket() {
            var defer = $q.defer(),
                data = {};

            $http({
                'method': 'GET',
                'url': BASE_URL + '/Cart-getBasket?source=basket'
            }).then(function (basketData) {
                defer.resolve(parseBasketData(basketData.data));
                if (document.getElementById('jwsdw-basket-module').classList.contains('jws-cart-page')) {
                    data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;
                    $http({
                        'method': 'POST',
                        'url': BASE_URL + '/Cart-reporting',
                        'params': data
                    });
                }
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to add multiple items to the basket.
         * @param {String[]} productIds ids of the products that are added.
         * @returns {Promise} promise with the parsed basket data
         */
        function addItems(productIds) {
            var defer = $q.defer();
            ocapiBasketService.add(productIds).then(function(basketData) {
                defer.resolve(parseBasketData(basketData.data));
            });
            return defer.promise;
        }

        /**
         * @description Method to add a product to the basket.
         * @param {String} productId id of the product that should be added
         * @param {Number} quantity quantity with which the new product should be added
         * @param {Object} product product object for the product that should be added
         * @param {boolean} isRecommendedProduct boolean if the item is recommended product
         * @returns {Promise} promise with the parsed basket data
         */
        function addItem(productId, quantity, product, isRecommendedProduct) {
            var defer = $q.defer(),
                data = {
                    'productId': productId,
                    'quantity': quantity,
                    'source': 'basket'
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-addProduct',
                'params': data
            }).then(function (basketData) {
                if (!isRecommendedProduct) {
                    trackAddToBasketService.sendOCAPI(product, quantity);
                } else {
                    trackAddToBasketService.sendAddToCartEventForRecommendedProduct(product, quantity);
                }

                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to add a bonus product to the basket.
         * @param {String} productId id of the bonus product that should be added
         * @param {Object} product product object for the product that should be added
         * @returns {Promise} promise with the parsed basket data
         */
        function addBonusItem(productId, product) {
            var defer = $q.defer(),
                data = {
                    'pids': productId,
                    'action': 'addToCart'
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-createBonusProductLineItems',
                'params': data
            }).then(function (basketData) {
                trackAddToBasketService.sendOCAPI(product, 1);

                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to remove a product from the basket.
         * @param {String} itemId product item id (not product id) for the product that is removed.
         * @returns {Promise} promise with the parsed basket data
         */
        function removeItem(itemId) {
            var defer = $q.defer(),
                data = {
                    'itemId': itemId
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-removeProduct',
                'params': data
            }).then(function (basketData) {
                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }

        /**
         * @description Method to update the quantity of a product.
         * @param {String} itemId product item id (not product id) for the product that is updated.
         * @param {Number} quantity new quantity for the product
         * @returns {Promise} promise with the parsed basket data
         */
        function updateQuantity(itemId, quantity) {
            var defer = $q.defer(),
                oldQuantity = _basketData.products.find(function(product) {
                    return product.basketItemId === itemId;
                })._oldQuantity,
                addedToCart = quantity - oldQuantity,
                data = {
                    'itemId': itemId,
                    'quantity': quantity
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;


            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-updateProductQuantity',
                'params': data
            }).then(function (basketData) {
                var product = basketData.data.product_items.find(function (item) {
                    return item.item_id === itemId;
                });

                if (addedToCart > 0) {
                    trackAddToBasketService.send({
                        'products': [_getProductData(product.product_id, addedToCart)]
                    });
                }

                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                defer.reject(error.data);
            });
            return defer.promise;
        }

        /**
         * @description Method to fetch product data from tracking layer
         * @param {String} id product id
         * @param {Number} quantity quantity
         * @returns {Object} product data
         */
        function _getProductData(id, quantity) {
            var ids = window.dynamic_tm_data.basketProductIds.split(',');
            var names = window.dynamic_tm_data.basketProductNames.split(',');
            var prices = window.dynamic_tm_data.basketPricesWithVAT.split(',');
            var colors = window.dynamic_tm_data.basketProductColorNames.split(',');
            var categories = window.dynamic_tm_data.basketCategories.split(',');
            var index = ids.findIndex(function (i) {
                return i === id;
            });

            return {
                'name': names[index],
                'id': id,
                'price': prices[index],
                'color': colors[index],
                'quantity': quantity,
                'category': categories[index]
            };
        }
        /**
         * @description Method to add a coupon to the basket.
         * @param {String} couponCode coupon code for which the coupon is added to the basket if it exists.
         * @returns {Promise} promise with the parsed basket data or error data if rejected (used for session timeouts)
         */
        function addCoupon(couponCode) {
            var defer = $q.defer(),
                data = {
                    'couponCode': couponCode
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-addCoupon',
                'params': data
            }).then(function (basketData) {
                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                $log.debug('basket service errorData: ', error);
                defer.reject(error.data);
            });
            return defer.promise;
        }

        /**
         * @description Method to remove a coupon from the basket.
         * @param {String} couponId coupon item id (not coupon code) that is removed from the basket
         * @returns {Promise} promise with the parsed basket data
         */
        function removeCoupon(couponId) {
            var defer = $q.defer(),
                data = {
                    'couponId': couponId
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-removeCoupon',
                'params': data
            }).then(function (basketData) {
                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                $log.debug('basket service errorData: ', error);
                defer.reject(error.data);
            });
            return defer.promise;
        }

        /**
         * @description Method to remove a coupon from the basket.
         * @param {String} couponCode coupon code that is removed from the basket
         * @returns {Promise} promise with the parsed basket data
         */
        function removeBonusCoupon(couponCode) {
            var defer = $q.defer(),
                data = {
                    'couponCode': couponCode
                };

            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            $http({
                'method': 'POST',
                'url': BASE_URL + '/Cart-removeBonusCoupon',
                'params': data
            }).then(function (basketData) {
                defer.resolve(parseBasketData(basketData.data));
            }, function (error) {
                $log.debug('basket service errorData: ', error);
                defer.reject(error.data);
            });
            return defer.promise;
        }

        /**
         * @description Method to prepare the checkout for the current basket and return the validation result.
         * @returns {Promise} promise with the validation result
         */
        function prepareCheckout() {
            var defer = $q.defer();
            ocapiBasketService.prepareCheckout()
                .then(function (validationResult) {
                    defer.resolve(validationResult.data);
                })
                .catch(function (validationResult) {
                    defer.reject(validationResult.data);
                });
            return defer.promise;
        }

        /**
         * @description Method to direct the customer to the checkout.
         * The url is retrieved from the app window object which is filled by appresource.isml
         * @returns {String | undefined} the new url set as window.location.href, or undefined if the login picker opens.
         */
        function checkout() {
            $log.debug('jwsdw controller: checkout basket');

            if ($window.jwsdwSettings.isJwAgent) {
                return $window.location.href = $window.app.urls.cartCheckoutLogin;
            }
            if ($window.jwsdwSettings.customerAuthenticated) {
                return $window.location.href = $window.app.urls.cartCheckout;
            }

            $window.jwsdwApi.openCheckoutLoginPicker();
        }

        /**
         * @description Method to direct the customer to the direct checkout (PayPal Express).
         * The url is retrieved from the app window object which is filled by appresource.isml
         * @returns {String} the new url set as window.location.href
         */
        function directCheckout() {
            $log.debug('jwsdw controller: direct checkout basket');
            return $window.location.href = $window.app.urls.cartDirectCheckout;
        }

        /**
         * @description Method to parse raw basket data received from the ocapi.
         * @param {Object} basketData raw basket data as returned by the ocapi
         * @param {String} basketData.basket_id basket ID
         * @param {Number} basketData.order_total total order value
         * @param {Number} basketData.shipping_total total shipping costs
         * @param {Object[]} basketData.product_items product items
         * @param {Number} basketData.c_bonus_points bonus points equivalent
         * @param {Number} basketData.c_cart_quantity items in cart
         * @param {Number} basketData.c_free_shipping_amount free shipping amount
         * @param {Number} basketData.c_free_shipping_percentage free shipping percentage
         * @returns {Object} parsed basket data
         */
        function parseBasketData(basketData) {
            _basketData = {
                'id': basketData.basket_id,
                'orderTotal': basketData.order_total,
                'merchandizeTotalGrossPrice': basketData.c_merchandize_total_gross_price,
                'adjustedMerchandizeTotalPrice': basketData.c_adjusted_merchandize_total_price,
                'shippingTotal': basketData.shipping_total,
                'taxTotal': basketData.tax_total,
                'empty': (!basketData.product_items || basketData.product_items.length === 0) &&
                    (!basketData.loyalty_coupon_items || basketData.loyalty_coupon_items.length === 0) &&
                    (!basketData.gift_card_items || basketData.gift_card_items.length === 0),
                'bonusPoints': basketData.c_bonus_points || 0,
                'cartQuantity': basketData.c_cart_quantity || 0,
                'freeShippingAmount': basketData.c_free_shipping_amount || null,
                'freeShippingPercentage': basketData.c_free_shipping_percentage || null,
                'couponGiftCertificates': basketData.loyalty_coupon_items || [],
                'giftCards': basketData.gift_card_items || [],
                'applicablePaymentMethods': basketData.applicablePaymentMethods || [],
                'isLoyaltyCustomer': basketData.isLoyaltyCustomer,
                'isEmployeeAccount': basketData.isEmployeeAccount,
                'isCallawayAccount': basketData.isCallawayAccount,
                'isFranchiseStore': basketData.isFranchiseStore,
                'sessionCurrency': basketData.sessionCurrency,
                'applicableCreditCards': basketData.applicableCreditCards,
                'agent': basketData.agent,
                'isCustomerLoggedIn': basketData.isCustomerLoggedIn,
                'errorMessages': basketData.errorMessages,
                'recommendedProduct': basketData.recommendedProduct,
                'promotionTotal': basketData.promotion_total,
                'orderLimitReached': basketData.order_limit_reached || false,
                'orderLimitMsg': basketData.order_limit_msg || null,
                'refusalReason': basketData.refusal_reason || null,
                'showShippingNotification': basketData.showShippingNotification || false,
                'isRemovedBonusProduct': basketData.isRemovedBonusProduct,
                'defaultSelectableBonusProduct': basketData.defaultSelectableBonusProduct,
                'selectedBonusProductLineItems':basketData.selectedBonusProductLineItems,
                'outOfStockBonus': basketData.outOfStockBonus,
                'productLevelPriceAdjustments': basketData.product_level_price_adjustments,
                'totalNonCouponPriceAdjustments': basketData.total_non_coupon_price_adjustments,
                'approachingDiscounts': basketData.approachingDiscounts
            };

            if (basketData.c_discount_line_item) {
                if (basketData.c_discount_line_item.hasBonusDiscountLineItem) {
                    _basketData.hasBonusDiscountLineItem = basketData.c_discount_line_item.hasBonusDiscountLineItem;
                } else {
                    _basketData.hasBonusDiscountLineItem = false;
                }
                if (basketData.c_discount_line_item.maxBonusProducts) {
                    _basketData.maxBonusProducts = basketData.c_discount_line_item.maxBonusProducts;
                }
                if (basketData.c_discount_line_item.selectableBonusProducts) {
                    _basketData.selectableBonusProducts = basketData.c_discount_line_item.selectableBonusProducts;
                }
                if (basketData.c_discount_line_item.selectedBonusProducts) {
                    _basketData.selectedBonusProducts = basketData.c_discount_line_item.selectedBonusProducts;
                }
                if (basketData.c_discount_line_item.allBonusProductVariants) {
                    _basketData.allBonusProductVariants = basketData.c_discount_line_item.allBonusProductVariants;
                }
                if (basketData.c_discount_line_item.hideBonusProductImage) {
                    _basketData.hideBonusProductImage = basketData.c_discount_line_item.hideBonusProductImage;
                }
            } else {
                _basketData.hasBonusDiscountLineItem = false;
            }

            // parse complex data types for the basket
            _basketData.coupons = (basketData.coupon_items || []).map(_parseCouponData);
            _basketData.couponGiftCertificates = (_basketData.couponGiftCertificates || []).map(function(giftCert) {
                giftCert.valid = true;
                return giftCert;
            });
            _basketData.products = (basketData.product_items || []).map(_parseProductData);
            _basketData.orderCouponPriceAdjustments = (basketData.order_coupon_price_adjustments || []).map(_parsePriceAdjustmentData);
            _basketData.orderPriceAdjustments = (basketData.order_price_adjustments || []).map(_parsePriceAdjustmentData);

            return _basketData;
        }

        /**
         * @description Method to parse raw coupon data.
         * @param {Object} product product data object
         * @returns {Object} parsed product data object
         * @private
         */
        function _parseProductData(product) {
            return {
                'id': product.product_id,
                'basketItemId': product.item_id,
                'basePrice': product.base_price,
                'priceAdjustments': (product.price_adjustments || []).map(_parsePriceAdjustmentData),
                'priceAfterItemDiscount': product.price_after_item_discount,
                'bestPrice': product.best_price,
                'isPersonalizedPriceAdjustment': product.is_personalized_price_adjustment,
                'adjustedUnitPrice': product.price_after_item_discount / product.quantity,
                'totalListPrice': 0,
                'taxRate': product.tax_rate,
                'price': product.price,
                'name': product.product_name,
                'quantity': product.quantity,
                '_oldQuantity': product.quantity,
                'isBonusProduct': product.bonus_product_line_item,
                'bonusProductMessage': product.bonus_product_message,
                'isGiftcard': _isGiftCard(product.product_id)
            };
        }

        /**
         * @description Method to parse raw coupon data.
         * @param {String} productId product ID
         * @returns {Boolean} true if product is giftcard
         * @private
         */
        function _isGiftCard(productId) {
            return GIFTCARDS.filter(function(masterId) {
                return productId.indexOf(masterId) !== -1;
            }).length > 0;
        }

        /**
         * @description Method to parse raw coupon data.
         * @param {Object} coupon raw coupon data object
         * @returns {Object} parsed coupon data object
         * @private
         */
        function _parseCouponData(coupon) {
            return {
                'code': coupon.code,
                'id': coupon.coupon_item_id,
                'isApplied': coupon.isApplied,
                'valid': coupon.valid,
                'calloutMessage': coupon.calloutMessage,
                'promoTagName': coupon.promoTagName
            };
        }

        /**
         * @description Method to parse raw price adjustment data of products and orders.
         * @param {Object} priceAdjustment raw price adjustment data object
         * @returns {Object[]} parsed price adjustment data object
         * @private
         */
        function _parsePriceAdjustmentData(priceAdjustment) {
            var parsedPriceAdjustment = {
                'couponCode': priceAdjustment.coupon_code,
                'promotionName': priceAdjustment.item_text,
                'price': priceAdjustment.price,
                'formattedPrice': priceAdjustment.formatted_price,
                'promotionId': priceAdjustment.promotion_id,
                'promoTagName': priceAdjustment.promo_tag_name,
                'excludeFromTotalDiscountCalculation': priceAdjustment.exclude_from_total_discount_calculation,
                'callOutMessage': priceAdjustment.callout_message,
                'percentage': priceAdjustment.percentage,
                'amount': priceAdjustment.amount,
                'formattedAmount': priceAdjustment.formatted_amount
            };

            if (parsedPriceAdjustment.couponCode) {
                _setCouponActive(parsedPriceAdjustment.couponCode);
            }

            return parsedPriceAdjustment;
        }

        /**
         * @description Method to check if a price adjustment coupon code has an active coupon and adds a boolean to the coupon.
         * @param {String} couponCode coupon code which is checked against basket coupons
         * @returns {Boolean} true if the coupon code in a price adjustment is in basket coupons false otherwise
         * @private
         */
        function _setCouponActive(couponCode) {
            var matchingCoupon = _basketData.coupons.find(function (coupon) {
                return coupon.code === couponCode;
            });
            matchingCoupon.hasActivePriceAdjustment = !!matchingCoupon;
            return matchingCoupon.hasActivePriceAdjustment;
        }
    }
}(angular));
